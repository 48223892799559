import React from 'react'
import { withNamespaces } from 'react-i18next'
import '../../../i18n'
import classes from './AboutHeader.module.scss'

const AbouHeader = ({ t }) => {
  return (
    <div className={classes.aboutHeader}>
      <div className={classes.title}>
        <h1>{t('headerONas')}</h1>
      </div>
    </div>
  )
}

export default withNamespaces()(AbouHeader)
