import React, { useContext, useEffect, useState } from 'react'
import { Box } from '@material-ui/core'
import { GlobalStateContext } from '../../context/GlobalContextProvider'

import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../../hoc/layout/layout'
import SEO from '../../hoc/seo'
import Grid from '@material-ui/core/Grid'
import classes from './About.module.scss'
import Youtube from '../../components/Youtube/Youtube'

import AboutHeader from './AboutHeader/AboutHeader'

const About = () => {
  const state = useContext(GlobalStateContext)
  let data = useStaticQuery(graphql`
    query {
      polishSEO: allDirectusMainpageTranslation(
        filter: { language: { eq: "pl" } }
      ) {
        edges {
          node {
            onas
          }
        }
      }
      englishSEO: allDirectusMainpageTranslation(
        filter: { language: { eq: "en" } }
      ) {
        edges {
          node {
            onas
          }
        }
      }
      germanSEO: allDirectusMainpageTranslation(
        filter: { language: { eq: "de" } }
      ) {
        edges {
          node {
            onas
          }
        }
      }
      german: allDirectusAboutpageTranslation(
        filter: { language: { eq: "de" } }
      ) {
        edges {
          node {
            osoba1
            osoba1info
            osoba1photo {
              data {
                full_url
              }
            }
            osoba2
            osoba2info
            osoba2photo {
              data {
                full_url
              }
            }
            rekomendacje
            youtubeid
          }
        }
      }
      polish: allDirectusAboutpageTranslation(
        filter: { language: { eq: "pl" } }
      ) {
        edges {
          node {
            osoba1
            osoba1info
            osoba1photo {
              data {
                full_url
              }
            }
            osoba2
            osoba2info
            osoba2photo {
              data {
                full_url
              }
            }
            rekomendacje
            youtubeid
          }
        }
      }
      english: allDirectusAboutpageTranslation(
        filter: { language: { eq: "en" } }
      ) {
        edges {
          node {
            osoba1
            osoba1info
            osoba1photo {
              data {
                full_url
              }
            }
            osoba2
            osoba2info
            osoba2photo {
              data {
                full_url
              }
            }
            rekomendacje
            youtubeid
          }
        }
      }
    }
  `)
  useEffect(() => {
    setFirstP(`url(${edges[0].node.osoba1photo.data.full_url})`)
    setSecondP(`url(${edges[0].node.osoba2photo.data.full_url})`)
  })

  const [firstP, setFirstP] = useState(null)
  const [secondP, setSecondP] = useState(null)

  let edges = null
  let seo = null
  if (state.lang === 'pl-PL' && data) {
    edges = data.polish.edges
    seo = data.polishSEO.edges
  } else if (state.lang === 'de-DE' && data) {
    edges = data.german.edges
    seo = data.germanSEO.edges
  } else {
    edges = data.english.edges
    seo = data.englishSEO.edges
  }

  let movie = (
    <Grid container style={{ backgroundColor: '#f2f2f2' }}>
      <Grid item xs={12}>
        <div className={classes.aboutBox}>
          <div className={classes.movie}>
            <div className={classes.recomendation}>
              <h1>{edges[0].node.rekomendacje}</h1>
            </div>
            <Youtube
              videoId={edges[0].node.youtubeid}
              className={classes.movie}
              onReady="null"
            />
          </div>
        </div>
      </Grid>
    </Grid>
  )

  if (!edges[0].node.youtubeid) {
    movie = null
  }
  return (
    <Layout>
      <SEO title={seo[0].node.onas} />
      <AboutHeader />
      <div className={classes.aboutSection}>
        <div style={{ backgroundColor: '#f2f2f2' }}>
          <Grid container style={{ maxWidth: 1480, margin: 'auto' }}>
            <Grid item xs={12} md={6}>
              <div className={classes.aboutBox}>
                <div
                  className={classes.image}
                  style={{
                    backgroundImage: firstP,
                  }}
                ></div>
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.aboutBox}>
                <div className={classes.aboutDetails}>
                  <h1>{edges[0].node.osoba1}</h1>
                  <p>{edges[0].node.osoba1info}</p>
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div style={{ backgroundColor: '#ffffff' }}>
          <Grid container style={{ maxWidth: 1480, margin: 'auto' }}>
            <Box clone order={{ xs: 2, md: 1 }}>
              <Grid item xs={12} md={6}>
                <div className={classes.aboutBox}>
                  <div className={classes.aboutDetails}>
                    <h1>{edges[0].node.osoba2}</h1>
                    <p>{edges[0].node.osoba2info}</p>
                  </div>
                </div>
              </Grid>
            </Box>

            <Box clone order={{ xs: 1, md: 2 }}>
              <Grid item xs={12} md={6}>
                <div className={classes.aboutBox}>
                  <div
                    className={classes.image}
                    style={{
                      backgroundImage: secondP,
                    }}
                  ></div>
                </div>
              </Grid>
            </Box>
          </Grid>
        </div>
        {movie}
      </div>
    </Layout>
  )
}

export default About
